<template>
<section class="domain_content">
    <!-- privacy policy -->
    <div class="sub-page">
        <h1>Privacy Policy</h1>
        <p class="small">[Last Updated] Oct 10, 2024</p>
        <div class="part">
            <h2>
                1. Personal Information We Collect
            </h2>
            <p>
                The information we gather is based on how you interact with our website and services. This may include personal details such as your name, contact information, browsing history, and device-related data. The scope of information collected is determined by the specific services you use and your choices while navigating our site.
            </p>
            <h2>
                2. Handling of Sensitive Information
            </h2>
            <p>
                We do not collect or process sensitive personal data, such as details related to your racial or ethnic background, sexual orientation, or religious beliefs. Our data collection practices are focused solely on non-sensitive information and are compliant with relevant legal standards.
            </p>
            <h2>
                3. Collection of Information from Third Parties
            </h2>
            <p>
                We do not obtain personal information from third-party sources. All data collected is derived directly from your interactions with our website and services.
            </p>
            <h2>
                4. How We Protect Your Information
            </h2>
            <p>
                We implement industry-standard security protocols to protect your personal information. However, please be aware that no online system or storage method is completely secure. While we take measures to safeguard your data, we cannot guarantee absolute protection from unauthorized access or cyber threats. If you have any concerns or need assistance, please feel free to contact us.
            </p>
        </div>
    </div>
</section>
</template>

  
  
<script>
import '@/css/main.scss'
const title = 'Privacy Policy'
const description = 'The privacy policy related to your information was written in this page.'
export default {
    metaInfo: {
        title,
        meta: [{
                name: 'description',
                content: description,
            },
            {
                name: 'title',
                content: title,
            },
            {
                property: 'og:title',
                content: title,
            },
            {
                property: 'og:description',
                content: description,
            },
        ],
        "link": [{
            "rel": "canonical",
            "href": "https://gbwhatsapk.chat/privacy/"
        }]
    },
}
</script>
